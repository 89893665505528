<template>
  <div>
    <b-button
      type="submit"
      variant="primary"
      :to="`/tracker/detail/${uuid}`"
      size="md"
      class="mb-1"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">{{$t('Labels.Back')}}</span>
    </b-button>
    <b-card>
      <h4>
          {{$t("Labels.TrackerInfo")}}
      </h4>
      <b-row>
        <b-col cols="12" md="6">
          <b-form
            class=""
            @submit.prevent
          >
            <validation-observer ref="configValidation">
              <b-form-group
                :label="$t('Labels.Alias')"
                label-for="alias"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Alias"
                  rules="required"
                >
                  <b-form-input
                    id="alias"
                    v-model="response.name"
                    :state="errors.length > 0 ? false:null"
                    name="alias"
                    :placeholder="$t('Labels.Alias')"
                    v-on:keyup.13="validationForm()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <h5 class="text-center mt-2">{{$t("Labels.Alerts")}}</h5>
              <hr>
              <b-row v-align="center">
                <b-col>
                  <b-card-text class="mb-0">
                    {{$t("Labels.Notifications")}}
                  </b-card-text>
                  <b-form-checkbox
                    checked="true"
                    class="custom-control-primary"
                    name="check-button"
                    switch
                    v-model="response.notifications"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="BellIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="BellOffIcon" />
                    </span>
                  </b-form-checkbox>
                </b-col>
                <b-col>
                  <b-card-text class="mb-0">
                    <span v-if="response.alertInOut">{{$t("Labels.Enter")}}</span>
                    <span v-else>{{$t("Labels.Exit")}}</span>
                  </b-card-text>
                  <b-form-checkbox
                    checked="true"
                    class="custom-control-primary"
                    name="check-button"
                    switch
                    v-model="response.alertInOut"
                  >
                    <span class="switch-icon-left">
                      <iconBase iconName="enter" :width="18" :height="18" iconColor="#626262"><iconLocationEnter/></iconBase>
                    </span>
                    <span class="switch-icon-right">
                      <iconBase iconName="exit" :width="18" :height="18" iconColor="#626262"><iconLocationExit/></iconBase>
                    </span>
                  </b-form-checkbox>
                </b-col>
                <b-col>
                  <b-card-text class="mb-0">
                    {{$t("Labels.RadiusAlert")}}
                  </b-card-text>
                  <vue-slider
                    v-model="response.radius_alert"
                    :min="minRadius"
                    :max="maxRadius"
                    direction="ltr"
                    :interval="1"
                  />
                  <div>{{response.radius_alert}} mts</div>
                </b-col>
              </b-row>
              <h5 class="text-center mt-2">{{$t("Labels.ReportTime")}}</h5>
              <hr>
              <b-row v-align="center">
                <b-col>
                  <b-form-spinbutton
                    :min="1"
                    v-model="response.time_notifications"
                    :max="1000"
                    class="text-xs"
                    size="sm"
                  />
                </b-col>
                <b-col>
                  <span>{{$t("Labels.Seconds")}}</span>
                </b-col>
              </b-row>
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
                class="mt-3"
              >
                {{$t("Labels.Save")}}
              </b-button>
            </validation-observer>
          </b-form>
        </b-col>
        <b-col cols="12" md="6">
          <div class="map-container">
            <l-map :center="center" :zoom="zoom" v-if="status_mapa">
                <l-tile-layer :attribution="attribution" :url="url">
                </l-tile-layer>
                <l-marker :draggable="draggable" :lat-lng.sync="marker" ref="position_marker">
                    <l-icon :icon-anchor="[12, 27]" >
                      <img :src="require(`@/assets/images/markers/danger.svg`)" width="25px;" alt="ícono marcador"/>
                  </l-icon>
                </l-marker>
                <l-circle
                  :lat-lng="marker"
                  :radius="response.radius_alert"
                  color="#EA5455"
                />
            </l-map>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { LMap, LTileLayer, LMarker, LIcon, LCircle } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormSpinbutton,
  BFormCheckbox
} from 'bootstrap-vue'
import { required } from '@validations'
import L from 'leaflet'
import request from '@/libs/request/index'
import { empty } from '@/libs/tools/helpers'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import iconBase from '@core/spore-components/icons/iconBase'
import iconLocationEnter from '@core/spore-components/icons/iconLocationEnter'
import iconLocationExit from '@core/spore-components/icons/iconLocationExit'

const DATA = {
  code: 200,
  data: {
    uuid: 'cdfbacef-3540-41b5-aae3-448bcd330df4',
    deveui: '0011223344556677',
    serial: '1111111',
    imei: '1111111',
    alias: 'tracker 1',
    status: '1',
    latlong: '20.6738686,-103.3704324',
    created_at: '2021-08-20 17:29:11',
    updated_at: '2021-08-23 11:08:06',
    time: 15,
    unity: 'minutes',
    label_location: 'ubicacion'
  },
  msg: 'Ok'
}

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormSpinbutton,
    ValidationProvider,
    ValidationObserver,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LCircle,
    BFormCheckbox,
    VueSlider,
    iconBase,
    iconLocationEnter,
    iconLocationExit
  },
  data () {
    return {
      response: {},
      required,
      zoom: 17,
      center: L.latLng(47.41322, -1.219482),
      url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
      attribution: '',
      marker: L.latLng(47.41322, -1.219482),
      draggable: true,
      status_mapa: false,
      DATA,
      time: 0,
      time_unity: '',
      uuid: this.$route.params.uuid,
      alert: [],
      minRadius: process.env.VUE_APP_MIN_RADIUS || 50,
      maxRadius: process.env.VUE_APP_MAX_RADIUS || 1000,
      spin: 10
    }
  },
  methods: {
    async getResponse () {
      const params = {
        url: `tracker/${this.uuid}`,
        method: 'GET'
      }
      await request(params).then(data => {
        this.response = data.data.data
        this.response.notifications = this.response.enabled_notifications === '1'
        this.response.alertInOut = this.response.alert_in_radius === 'I'
        this.response.time_notifications = parseInt(this.response.time_notifications)
        if (!empty(this.response.latlong_alert_string)) {
          const [lat, lng] = this.response.latlong_alert_string.split(',')
          this.marker = L.latLng(lat, lng)
          this.center = L.latLng(lat, lng)
          this.status_mapa = true
        } else if (!empty(this.response.lat) && !empty(this.response.long)) {
          this.marker = L.latLng(this.response.lat, this.response.long)
          this.center = L.latLng(this.response.lat, this.response.long)
          this.alert = [this.response.lat, this.response.long]
          this.status_mapa = true
        }
      })
      if (this.response.radius_alert < this.minRadius) {
        this.response.radius_alert = this.minRadius
      }
    },
    async save () {
      const location = this.$refs.position_marker.mapObject.getLatLng()
      const params = {
        url: `tracker/${this.uuid}`,
        method: 'PUT',
        params: {
          latlong_alert_string: `${location.lat},${location.lng}`,
          name: this.response.name,
          alert_in_radius: this.response.alertInOut ? 'I' : 'O',
          radius_alert: this.response.radius_alert,
          enabled_notifications: this.response.notifications ? '1' : '0',
          time_notifications: this.response.time_notifications
        }
      }
      await request(params)
    },
    validationForm () {
      this.$refs.configValidation.validate().then(success => {
        if (success) {
          this.save()
        }
      })
    }
  },
  created () {
    this.getResponse()
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/Config.scss";
</style>
